<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.id ? '编辑用户' : '新增用户' }}
      </el-breadcrumb-item>
    </Crumbs>

    <div class="fullScreenMain" v-loading="loading">
      <el-tabs class="fullScreenMainHeader" v-model="activeName">
        <el-tab-pane label="基本信息"></el-tab-pane>
        <el-tab-pane label="劳动合同" v-show="form.id"></el-tab-pane>
        <el-tab-pane label="附件"></el-tab-pane>
        <el-tab-pane label="履历"></el-tab-pane>
      </el-tabs>

      <el-button
        class="depart"
        @click="depart"
        type="danger"
        v-show="activeName == 0 && options.id && !isDepart"
        >离职</el-button
      >

      <div class="fullScreenMainCon">
        <el-form
          class="column3"
          ref="form"
          label-width="8em"
          :model="form"
          :rules="rules"
          v-show="activeName == 0"
        >
          <!-- 照片 -->
          <div
            ref="picturePath"
            class="avatar-uploader"
            @click="input_tx.click()"
            style="margin-right: 1000px"
          >
            <img v-if="form.picturePath" :src="form.picturePath" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>

          <!-- 基础信息 -->
          <el-card style="width: 42%">
            <el-descriptions
              class="margin-top"
              title="基础信息"
              direction="vertical"
              :column="3"
              border
            >
              <el-descriptions-item label="姓名">
                <el-form-item label-width="0px" style="width: 100%" prop="userName" ref="userName">
                  <el-input v-model="form.userName" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="账户名">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="loginName"
                  ref="loginName"
                >
                  <el-input v-model="form.loginName" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="性别">
                <el-form-item label-width="0px" style="width: 100%" prop="sex" ref="sex">
                  <el-select v-model="form.sex" placeholder="请选择">
                    <el-option label="男" value="男"></el-option>
                    <el-option label="女" value="女"></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="年龄">
                <el-form-item label-width="0px" style="width: 100%" prop="age" ref="age">
                  <el-input v-model="form.age"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="联系电话">
                <el-form-item label-width="0px" style="width: 100%" prop="phone" ref="phone">
                  <el-input v-model="form.phone" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="邮箱">
                <el-form-item label-width="0px" style="width: 100%" prop="mailbox" ref="mailbox">
                  <el-input v-model="form.mailbox" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="现居地址" :span="2">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="currentAddress"
                  ref="currentAddress"
                >
                  <el-input v-model="form.currentAddress" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>

          <!-- 工作信息 -->
          <el-card style="width: 57%; margin-left: 10px; margin-top: 0px">
            <el-descriptions
              class="margin-top"
              title="工作信息"
              direction="vertical"
              :column="4"
              border
            >
              <el-descriptions-item label="员工编号">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="staffNumber"
                  ref="staffNumber"
                >
                  <el-input v-model="form.staffNumber" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="部门" span="2">
                <el-form-item label-width="0px" style="width: 100%" prop="deptList" ref="deptList">
                  <!-- <el-select
                    collapse-tags
                    v-model="form.deptList"
                    placeholder="请选择"
                    multiple
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(n, k) in deptList"
                      :key="k"
                      :label="n.deptName"
                      :value="n.id"
                    ></el-option>
                  </el-select> -->
                  <!-- <el-cascader
                    style="width: 100%"
                    placeholder="请选择部门"
                    collapse-tags
                    :options="deptTree"
                    :props="deptProps"
                    :key="cascaderKey"
                    v-model="form.deptList"
                    @change="handleDeptChange"
                  ></el-cascader> -->
                  <el-select
                    ref="select"
                    v-model="form.deptList"
                    multiple
                    @visible-change="visibleChange"
                    :popper-append-to-body="false"
                    placeholder="请选择部门"
                  >
                    <el-option
                      v-for="item in deptOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <!-- <el-input
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 4 }"
                    placeholder="请选择部门"
                    :value="form.deptListName"
                    @focus="onDeptTreeShow"
                  >
                  </el-input> -->
                  <DeptTreeSelectDialog
                    v-if="isDeptTreeShow"
                    :isDeptTreeShow.sync="isDeptTreeShow"
                    :selectedTreeData="form.deptList"
                    @getDeptList="onDeptSubmit"
                  ></DeptTreeSelectDialog>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="岗位">
                <el-form-item label-width="0px" style="width: 100%" prop="postList" ref="postList">
                  <el-select
                    v-model="form.postList"
                    placeholder="请选择"
                    multiple
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(n, index) in postList"
                      :key="index"
                      :label="n.postNames"
                      :value="n.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="职称">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="technicalPost"
                  ref="technicalPost"
                >
                  <el-input v-model="form.technicalPost" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="工资总额" v-if="permission(['USER_ZJEBJ'])">
                <el-form-item label-width="0px" style="width: 100%" prop="salary" ref="salary">
                  <el-input
                    v-model="form.salary"
                    @input="inputFn('salary')"
                    placeholder="请输入工资总额"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="职级">
                <el-form-item label-width="0px" style="width: 100%" prop="rank" ref="rank">
                  <el-input
                    v-model="form.rank"
                    @focus="focusRank"
                    placeholder="请输入职级"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item> -->
              <el-descriptions-item label="司龄">
                <el-form-item label-width="0px" style="width: 100%" prop="workAge" ref="workAge">
                  <el-input v-model="form.workAge" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="入职日期">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="entryDate"
                  ref="entryDate"
                >
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.entryDate"
                    :editable="false"
                    type="date"
                    placeholder="请选择入职日期"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="转正日期">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="positiveDate"
                  ref="positiveDate"
                >
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.positiveDate"
                    type="date"
                    placeholder="请选择转正日期"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="引进方式">
                <el-form-item label-width="0px" style="width: 100%" prop="source" ref="source">
                  <el-select v-model="form.source" placeholder="请选择" clearable filterable>
                    <el-option label="社会来源" value="社会来源"></el-option>
                    <el-option label="内推" value="内推"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="合同起始时间">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="beginDate"
                  ref="beginDate"
                >
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.beginDate"
                    type="date"
                    placeholder="请选择合同起始时间"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="合同终止时间">
                <el-form-item label-width="0px" style="width: 100%" prop="endDate" ref="endDate">
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.endDate"
                    type="date"
                    placeholder="请选择合同终止时间"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label=" "></el-descriptions-item>
            </el-descriptions>
          </el-card>

          <!-- 个人信息 -->
          <el-card>
            <el-descriptions
              class="margin-top"
              title="个人信息"
              direction="vertical"
              :column="6"
              border
            >
              <el-descriptions-item label="出生日期">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="birthdate"
                  ref="birthdate"
                >
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.birthdate"
                    type="date"
                    placeholder="请选择预计开始日期"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="生日方式">
                <el-form-item label-width="0px" style="width: 100%" prop="calendar" ref="calendar">
                  <el-select v-model="form.calendar" placeholder="请选择" filterable>
                    <el-option
                      v-for="item in calendar"
                      :key="item.dictVal"
                      :label="item.dictName"
                      :value="item.dictVal"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="民族">
                <el-form-item label-width="0px" style="width: 100%" prop="nation" ref="nation">
                  <el-input v-model="form.nation" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="户口类型">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="huKouType"
                  ref="huKouType"
                >
                  <el-select v-model="form.huKouType" placeholder="请选择" clearable filterable>
                    <el-option label="本地非农业" value="本地非农业"></el-option>
                    <el-option label="本地农业" value="本地农业"></el-option>
                    <el-option label="外地非农业" value="外地非农业"></el-option>
                    <el-option label="外地农业" value="外地农业"></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="户籍地址" :span="2">
                <el-form-item label-width="0px" style="width: 100%" prop="address" ref="address">
                  <el-input v-model="form.address" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="婚育情况">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="hunYuZhuangkuang"
                  ref="hunYuZhuangkuang"
                >
                  <el-select
                    v-model="form.hunYuZhuangkuang"
                    placeholder="请选择"
                    clearable
                    filterable
                  >
                    <el-option label="已婚已育" value="已婚已育"></el-option>
                    <el-option label="已婚" value="已婚"></el-option>
                    <el-option label="未婚" value="未婚"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="政治面貌">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="politicsStatus"
                  ref="politicsStatus"
                >
                  <el-select
                    v-model="form.politicsStatus"
                    placeholder="请选择"
                    clearable
                    filterable
                  >
                    <el-option label="党员" value="党员"></el-option>
                    <el-option label="团员" value="团员"></el-option>
                    <el-option label="群众" value="群众"></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="银行卡号">
                <el-form-item label-width="0px" style="width: 100%" prop="cardNo" ref="cardNo">
                  <el-input v-model="form.cardNo" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="开户行">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="openingBank"
                  ref="openingBank"
                >
                  <el-input v-model="form.openingBank" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="紧急联系人">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="emergencyUser"
                  ref="emergencyUser"
                >
                  <el-input v-model="form.emergencyUser" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="紧急联系人电话">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="emergencyPhone"
                  ref="emergencyPhone"
                >
                  <el-input v-model="form.emergencyPhone" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="备注">
                <el-form-item label-width="0px" style="width: 100%" prop="remark" ref="remark">
                  <el-input v-model="form.remark" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>

          <!-- 学历信息 -->
          <el-card>
            <el-descriptions
              class="margin-top"
              title="学历信息"
              direction="vertical"
              :column="6"
              border
            >
              <el-descriptions-item label="最高学历">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="highestEducation"
                  ref="highestEducation"
                >
                  <el-select
                    v-model="form.highestEducation"
                    placeholder="请选择"
                    clearable
                    filterable
                  >
                    <el-option label="博士" value="博士"></el-option>
                    <el-option label="硕士" value="硕士"></el-option>
                    <el-option label="本科" value="本科"></el-option>
                    <el-option label="大专" value="大专"></el-option>
                    <el-option label="中专" value="中专"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="毕业学校">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="graduateSchool"
                  ref="graduateSchool"
                >
                  <el-input v-model="form.graduateSchool" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="专业">
                <el-form-item label-width="0px" style="width: 100%" prop="major" ref="major">
                  <el-input v-model="form.major" placeholder="请输入"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="毕业时间">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="graduationDate"
                  ref="graduationDate"
                >
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.graduationDate"
                    type="date"
                    placeholder="请选择毕业时间"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>

          <!-- 离职信息 -->
          <el-card v-if="isDepart">
            <el-descriptions
              class="margin-top"
              title="离职信息"
              direction="vertical"
              :column="6"
              border
            >
              <el-descriptions-item label="离职时间">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="departDate"
                  ref="departDate"
                >
                  <el-date-picker
                    value-format="timestamp"
                    v-model="form.departDate"
                    type="date"
                    placeholder="请选择合同起始时间"
                  ></el-date-picker>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="离职原因">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="departReason"
                  ref="departReason"
                >
                  <el-input v-model="form.departReason"></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="离职方式">
                <el-form-item
                  label-width="0px"
                  style="width: 100%"
                  prop="departType"
                  ref="departType"
                >
                  <el-select v-model="form.departType" placeholder="请选择" filterable>
                    <el-option
                      v-for="item in departStatus"
                      :key="item.dictVal"
                      :label="item.dictName"
                      :value="item.dictVal"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="离职附件">
                <el-form-item
                  label-width="0px"
                  style="width: 100%; text-align: left"
                  class="table"
                  prop="departFiles"
                  ref="departFiles"
                >
                  <Upload type="DEPART" @upload="upload"></Upload>
                  <div v-for="(n, index) in form.departFiles" :key="index" style="margin-top: 5px">
                    <a
                      class="fj-text"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.attachmentPath}`"
                    >
                      {{ n.attachmentName }}
                    </a>
                    <i @click="del(index)" class="iconfont iconerror iconCost"></i>
                  </div>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-form>
        <!-- 变职级信息弹窗 -->
        <el-dialog
          append-to-body
          :title="form.id ? '编辑职级' : '新增职级'"
          :visible.sync="rankDialog"
          width="26%"
          :before-close="cancelRank"
        >
          <el-form-item label="部门名称" prop="rankDeptId" ref="rankDeptId">
            <el-select
              value-key="id"
              @change="rankDeptIdFn"
              v-model="form.rankDeptId"
              placeholder="请选择部门"
            >
              <el-option
                v-for="item in rankDeptList"
                :label="item.deptName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职级" prop="" ref="">
            <el-select value-key="id" v-model="form.rank" placeholder="请选择职级" @change="rankFn">
              <el-option
                v-for="item in rankList"
                :label="item.rank"
                :value="{ id: item.id, rank: item.rank }"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancelRank">取 消</el-button>
            <el-button type="primary" @click="submitRank">确 定</el-button>
          </div>
        </el-dialog>

        <!-- 离职弹窗 -->
        <el-dialog append-to-body title="离职" :visible.sync="departDialog" width="50%">
          <el-form ref="departForm" :model="departForm" :rules="rulesDepart" label-width="100px">
            <el-form-item label="最后工作日" prop="departDate" ref="departDate">
              <el-date-picker
                value-format="timestamp"
                v-model="departForm.departDate"
                type="date"
                placeholder="请选择最后工作日时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="离职原因" prop="departReason" ref="departReason">
              <el-input
                type="textarea"
                v-model="departForm.departReason"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="离职方式" prop="departType" ref="departType">
              <el-select v-model="departForm.departType" placeholder="请选择" filterable>
                <el-option
                  v-for="item in departStatus"
                  :key="item.dictVal"
                  :label="item.dictName"
                  :value="item.dictVal"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="相关附件" prop="departFiles" ref="departFiles">
              <UploadList v-model="uploadData" :staffId="form.id"></UploadList>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="departDialog = false">取 消</el-button>
            <el-button type="primary" @click="submitDepart" :loading="departLoading"
              >确 定</el-button
            >
          </div>
        </el-dialog>

        <!-- 劳动合同 -->
        <Agreement
          :manageId="form.id"
          :file_Data="file_Data"
          v-show="activeName == 1 && options.id"
        ></Agreement>
        <!-- 附件 -->
        <Annex
          :manageId="form.id"
          v-show="activeName == 2 || (activeName == 1 && !form.id)"
          @changed="fileData($event)"
        >
        </Annex>
        <!-- 履历 -->
        <Resume
          :manageId="form.id"
          :resume.sync="form.resume"
          :labelList.sync="form.labelList"
          v-show="activeName == 3"
        >
        </Resume>
      </div>
      <div class="fullScreenOperation">
        <el-button type="success" :loading="loading" @click="handleSave"> 保存 </el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { phone, email } from '@/util/regular'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    Agreement: () => import('./Agreement.vue'),
    Resume: () => import('./Resume.vue'),
    UploadList: () => import('@/components/upload/ContractList.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DeptTreeSelectDialog: () => import('@/components/dept/DeptTreeSelectDialog.vue'),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isDepart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false, //修改保存键
      departLoading: false, //离职弹窗确认键
      showEditDialog: false, //该页面的弹窗
      rankDialog: false, //职级弹窗
      departDialog: false, //离职弹窗
      form: {
        postList: [],
        deptList: [],
        departFiles: [],
        loginName: null,
        sex: null,
        age: null,
        phone: null,
        mailbox: null,
        currentAddress: null,
        staffNumber: null,
        technicalPost: null,
        salary: null,
        workAge: null,
        entryDate: null,
        positiveDate: null,
        source: null,
        beginDate: null,
        endDate: null,
        birthdate: null,
        calendar: null,
        nation: null,
        huKouType: null,
        address: null,
        hunYuZhuangkuang: null,
        politicsStatus: null,
        cardNo: null,
        openingBank: null,
        emergencyUser: null,
        emergencyPhone: null,
        remark: null,
        highestEducation: null,
        graduateSchool: null,
        major: null,
        graduationDate: null,
        departDate: null,
        departReason: null,
        departType: null,
        loginName: null,
      }, //用户信息
      formData: {},
      deptTree: [], // 部门树
      postList: [], //岗位列表
      type: 'portrait',
      activeName: 0,
      file_Data: [], //文件
      y_file_Data: [],
      rankDeptList: [], //职级部门列表
      rankList: [], //职级列表
      rankDeptId: '',
      rank: '',
      departForm: {}, //离职信息
      departStatus: [], //离职方式
      calendar: [], //新农历字典
      filepath: process.env.VUE_APP_FILEPATH,
      uploadData: [], //离职文件信息
      rules: {
        userName: [{ required: true, message: '请输入用户姓名', trigger: ['blur', 'change'] }],
        loginName: [{ required: true, message: '请输入登录帐号', trigger: ['blur', 'change'] }],
        deptList: [{ required: true, message: '请选择所属部门', trigger: ['change'] }],
        postList: [{ required: true, message: '请选择岗位', trigger: ['blur', 'change'] }],
        phone: [
          { required: true, message: '请输入联系电话', trigger: ['blur', 'change'] },
          { pattern: phone, message: '请输入正确的联系电话', trigger: ['change', 'blur'] },
        ],
        mailbox: [{ pattern: email, message: '请输入正确的邮箱', trigger: ['change', 'blur'] }],
        sex: [{ required: true, message: '请输入性别', trigger: ['blur', 'change'] }],
        departDate: [
          { required: true, message: '请选择最后工作日时间', trigger: ['blur', 'change'] },
        ],
        departReason: [{ required: true, message: '请输入离职原因', trigger: ['blur', 'change'] }],
        departType: [{ required: true, message: '请选择离职方式', trigger: ['blur', 'change'] }],
      },
      rulesDepart: {
        departDate: [
          { required: true, message: '请选择最后工作日时间', trigger: ['blur', 'change'] },
        ],
        departReason: [{ required: true, message: '请输入离职原因', trigger: ['blur', 'change'] }],
        departType: [{ required: true, message: '请选择离职方式', trigger: ['blur', 'change'] }],
      },

      isDeptTreeShow: false,
      deptOptions: [],
    }
  },
  inject: ['re'],
  mounted() {
    this.input_tx = document.createElement('input')
    this.input_tx.setAttribute('type', 'file')
    this.input_tx.addEventListener('change', e => {
      var files = e.target.files
      var tx = new FormData()
      tx.append('file', files[0])
      tx.append('type', this.type)
      this.$api.common
        .uploadFile(tx)
        .then(res => {
          this.form.picturePath = res.data.filePath + '?' + new Date().getTime()
          this.$forceUpdate()
        })
        .catch(err => {
          this.input_tx.value = ''
        })
    })
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        this.activeName = '0'
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal.id) {
          // 发起请求获取用户详情信息
          this.$api.user
            .selectStaffInfo(newVal.id)
            .then(res => {
              this.form = res.data
              this.form.departDeleteFiles = []
              this.formData = this.form.deepClone()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          // 新增用户-获取所有岗位
          this.getPostList()
        }
      },
    },

    'form.id': function (newVal, oldVal) {
      if (newVal) {
        this.getAttachmentList()
      }
    },
    'form.deptList': {
      immediate: true, //初始化立即执行
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          //更换部门或增加部门
          this.getPostList(newVal)
        }
      },
    },
    // "form.postId": function(newVal, oldVal) {
    //   if (newVal) {
    //     this.getPostList(newVal);
    //   }
    // },
    'form.entryDate': function (newVal, oldVal) {
      if (newVal) {
        let d1 = new Date(newVal).getFullYear()
        let d2 = new Date().getFullYear()
        let time = d2 - d1
        this.form.workAge = time
      }
    },
    'form.birthdate': function (newVal, oldVal) {
      if (newVal) {
        let d1 = new Date(newVal).getFullYear()
        let d2 = new Date().getFullYear()
        let time = d2 - d1
        this.form.age = time
      }
    },
    uploadData: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        let arr = []
        if (this.uploadData.length > 0) {
          for (let i = 0; i < this.uploadData.length; i++) {
            const e = {
              attachmentType: 'DEPART',
              attachmentName: this.uploadData[i].attachmentName,
              attachmentPath: this.uploadData[i].attachmentPath,
              remark: this.uploadData[i].remark,
              staffId: this.uploadData[i].staffId,
            }
            if (this.uploadData[i].id) e.id = this.uploadData[i].id
            arr.push(e)
          }
        }
        this.departForm.departFiles = arr
      },
    },
  },
  created() {
    this.$api.dict.listSysDictData('DEPART_STATUS', true).then(res => {
      this.departStatus = res.data
    })
    this.$api.dict.listSysDictData('STAFF_BIRTHDAY', true).then(res => {
      this.calendar = res.data
    })
    this.getDeptList()
    // this.getPostList();
  },
  methods: {
    inputFn(e) {
      this.form[e] = String(this.form[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },
    // 选职级
    rankDeptIdFn(r) {
      this.form.rankDeptId = r
      this.rankDeptId = r
      this.$forceUpdate()
    },
    rankFn(r) {
      this.form.rank = r.rank
      this.rank = r.rank
      this.form.rankId = r.id
      this.$forceUpdate()
    },
    submitRank() {
      this.formData.rankDeptId = this.rankDeptId
      this.formData.rank = this.rank
      this.rankDialog = false
    },
    cancelRank() {
      if (this.form.id) {
        this.form.rankDeptId = ''
        this.form.rank = ''
        this.form.rankDeptId = this.formData.rankDeptId
        this.form.rank = this.formData.rank
      }
      this.rankDialog = false
    },
    focusRank() {
      this.rankDeptList = []

      if (this.form.deptList.length > 0) {
        this.deptList.forEach(v => {
          this.form.deptList.forEach((n, i) => {
            if (v.id == n) {
              this.rankDeptList.push(v)
              if (i == 0) {
                if (!this.form.id || !this.form.rankDeptId) {
                  this.form.rankDeptId = this.rankDeptList[0].id
                  this.rankList = []
                  this.$api.user
                    .getRsSalaryManageList({
                      rankDeptId: this.rankDeptList[0].id,
                    })
                    .then(res => {
                      this.rankList = res.data.records
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }
              }
            }
          })
        })
      }
      this.rankDialog = true
    },

    /** 加载用户附件列表数据 **/
    getAttachmentList() {
      this.$api.user
        .getUserAttachmentList(this.options.id)
        .then(res => {
          this.file_Data = res.data
          this.y_file_Data = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .listDept()
        .then(res => {
          let deptList = res.data || []
          if (deptList && deptList.length > 0) {
            this.deptOptions = deptList.map(item => {
              return {
                label: item.deptName,
                value: item.id,
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "岗位" 列表数据 **/
    getPostList(gjz = []) {
      this.postList = []
      let obj = {}
      if (gjz.length > 0) {
        obj = { deptIdList: gjz }
      }
      // else {
      //   obj = { deptId: gjz };
      // }
      this.postList = []
      this.$api.sysConfig
        .listPost(obj)
        .then(res => {
          res.data.forEach(e => {
            e.postNames = e.deptName + ' - ' + e.postName
            this.postList.push(e)
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 确认修改
    handleSave() {
      var cond = {
        ...this.form,
        userAttachmentRequests: this.file_Data.deepClone(),
        userAttachmentDeleteList: [],
      }
      if (this.y_file_Data != []) {
        //判断被删除得文件
        this.y_file_Data.some(res => {
          if (
            !cond.userAttachmentRequests.some(r => {
              return r.id == res.id
            })
          ) {
            cond.userAttachmentDeleteList.push(res.id)
          }
        })
      }
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.editDialogLoading = true
          if (cond.labelList) {
            cond.labelList = cond.labelList.map(item => {
              return item.id
            })
          }
          this.$api.user
            .add(cond)
            .then(res => {
              this.editDialogLoading = false
              this.showEditDialog = false
              if (this.isDepart) {
                this.form.id = this.form.departId //离职信息修改接口的id为离职id
                this.$api.user
                  .dimission(this.form)
                  .then(res => {
                    this.$message.success('操作成功！')
                    this.re()
                  })
                  .catch(err => {
                    this.editDialogLoading = false
                    console.log(err)
                  })
              } else {
                this.$message.success('操作成功！')
                this.re()
              }
            })
            .catch(err => {
              this.editDialogLoading = false
              console.log(err)
            })
          this.rankDeptId = ''
        } else {
          this.scrollView(obj)
        }
      })
      this.rankDeptId = ''
    },

    fileData(e) {
      this.file_Data = e
    },

    //离职
    depart() {
      this.departForm = {}
      this.departForm.staffId = this.options.id
      this.uploadData = []
      this.departDialog = true
    },
    submitDepart() {
      this.$refs.departForm.validate(valid => {
        if (valid) {
          this.departLoading = true
          this.$api.user
            .dimission(this.departForm)
            .then(res => {
              this.departLoading = false
              this.departDialog = false
              this.$message.success('离职成功！')
              this.re()
            })
            .catch(err => {
              this.departLoading = false
              this.departDialog = false
              console.log(err)
            })
        }
      })
    },
    //上传离职附件
    upload(r) {
      if (r.length > 0) {
        for (let i = 0; i < r.length; i++) {
          const e = r[i]
          e.remark = ''
          e.staffId = this.form.staffId
          this.form.departFiles.push(e)
        }
      }
    },
    // 删除离职附件
    del(index) {
      console.log(this.form.departFiles[index].id)
      if (this.form.departFiles[index].id)
        this.form.departDeleteFiles.push(this.form.departFiles[index].id)
      this.form.departFiles.splice(index, 1)
    },

    //部门输入框获取焦点弹框显示部门选择树
    onDeptTreeShow() {
      this.isDeptTreeShow = true
    },

    visibleChange() {
      // 关闭选择下拉框，跳出弹框
      this.$refs.select.blur()
      this.isDeptTreeShow = true
    },

    onDeptSubmit(value) {
      if (value && value.length > 0) {
        this.form.deptList = value.map(item => item.id)
        this.$forceUpdate()
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.avatar-uploader {
  padding-bottom: 10px;
}

.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.avatar {
  width: 140px;
  height: 140px;
  display: block;
}

.depart {
  position: absolute;
  right: 30px;
  top: 70px;
}

.el-descriptions {
  width: 100%;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
